<template>
  <div class="notes">
    <div
      v-if="!milestoneNotes[milestoneId]?.isRequestPending"
      class="notes-wrapper"
      :class="{ contains: milestoneNotes[milestoneId]?.collection.length }"
    >
      <sticky-note
        v-for="note in milestoneNotes[milestoneId]?.collection"
        :key="note.id"
        :editable="true"
        :data="note"
        field="note"
        @edited="$e => onNotesEdited($e, note)"
        @deleted="onNotesDeleted(note)"
      />
      <sticky-note
        v-if="isAddingNote"
        :editable="true"
        :data="{}"
        :create-new="true"
        field="note"
        @edited="onNoteAdded"
        @deleted="isAddingNote = false"
      />
      <hub-button :disabled="isAddingNote" variant="icon" title="Add note" @click="isAddingNote = true"
        ><hub-icon name="sticker-plus-outline"
      /></hub-button>
    </div>
    <div v-else class="loading">
      <hub-icon name="loading" spin size="md"></hub-icon>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import StickyNote from '@/components/common/StickyNote';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  components: {
    StickyNote,
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    milestoneId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isAddingNote: false
    };
  },
  computed: {
    ...mapState({
      milestoneNotes: s => s.milestoneNotes.notes
    })
  },
  async created() {
    if (!this.milestoneNotes[this.milestoneId] && this.milestoneId) {
      await this.$store.dispatch('milestoneNotes/getNotesForMilestones', [this.milestoneId]);
    }
  },
  methods: {
    async onNotesEdited(v, note) {
      await this.$store.dispatch('milestoneNotes/updateNote', { id: note.id, payload: { note: v } });
    },
    async onNoteAdded(note) {
      try {
        await this.$store.dispatch('milestoneNotes/createNote', { note, milestoneId: this.milestoneId });
      } finally {
        this.isAddingNote = false;
      }
    },
    async onNotesDeleted(note) {
      await this.$store.dispatch('milestoneNotes/deleteNote', note);
    }
  }
};
</script>
<style lang="scss" scoped>
.notes {
  border-top: 1px solid var(--theme-highlight);
  padding: 5px 5px 5px 0px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
  .notes-wrapper {
    display: flex;
    align-items: flex-start;

    // div:nth-child(n + 2) {
    //   margin-left: -50px;
    // }

    &.contains {
      padding-bottom: 100px;
    }
  }
}
</style>
